import React, { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../API";
import useFHConnectUser from "./useFHConnectUser";

export const AdminContext = React.createContext({
  admin: {
    token: "",
    exp: 0,
    email: "",
    permissions: [""],
    role: "",
  },
  loading: false,
  singIn: async () => {},
  logout: () => {},
  logoutUser: () => {},
  signInWithToken: async () => {},
  handleRefreshUser: () => {},
});

const useAdmin = () => {
  return useContext(AdminContext);
};

/**
 * @author Giuliano Antonanzas
 */
export const AdminCustomerContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const FHConnectUser = useFHConnectUser();

  const [admin, setAdmin] = useState({
    token: localStorage.getItem("NFTdoorAdminToken"),
    exp: localStorage.getItem("NFTdoorAdminTokenExp"),
    email: localStorage.getItem("NFTdoorAdminTokenEmail"),
    permissions: localStorage.getItem("NFTdoorAdminPermissions"),
    role: JSON.parse(localStorage.getItem("NFTdoorAdminRole")),
  });

  const [loading, setLoading] = useState();

  const currentEnv = localStorage.getItem("currentEnvironment");

  const logout = useCallback(() => {
    localStorage.clear();
    localStorage.setItem("currentEnvironment", currentEnv);
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("NFTdoorAdminToken");
    localStorage.removeItem("NFTdoorAdminTokenExp");
    localStorage.removeItem("NFTdoorAdminPermissions");
    localStorage.removeItem("NFTdoorAdminRole");
  };

  const singIn = useCallback(async (email, password) => {
    setLoading(true);
    const result = await API.post({
      url: "/login",
      data: {
        email,
        password,
      },
    });

    if ("error" in result) {
      setLoading(false);
      return result.error;
    }

    const payload = result?.data?.authData?.idToken?.payload;
    if (payload) {
      FHConnectUser(payload["cognito:username"], payload.email, payload.email);
    }

    setAdmin({
      token: result.data?.authData?.idToken?.jwtToken,
      exp: result.data?.authData?.idToken?.payload.exp * 1000,
      email: result.data?.authData?.idToken?.payload?.email,
      permissions: result.data?.permissions,
      role: result?.data?.role,
    });

    const permissions = result?.data?.permissions || [];
    const specificPermission = "2efaf7e1-cc7d-4c1f-a729-c33bacea2c75";

    const hasViewBrokerApp = permissions.includes(specificPermission);

    const userRole = result?.data?.role;
    const defaultBrokerAppRoute =
      userRole === "Account Executive" ||
      userRole === "Broker" ||
      userRole === "Broker REMN" ||
      userRole === "Broker HomeBridge";

    localStorage.setItem(
      "NFTdoorAdminId",
      result.data?.authData?.accessToken?.payload?.sub
    );
    localStorage.setItem(
      "NFTdoorAdminToken",
      result.data?.authData?.idToken?.jwtToken
    );
    localStorage.setItem(
      "NFTdoorAdminTokenExp",
      result.data?.authData?.idToken?.payload.exp * 1000
    );
    localStorage.setItem(
      "NFTdoorAdminTokenEmail",
      result.data?.authData?.idToken?.payload?.email
    );
    localStorage.setItem(
      "NFTdoorAdminPermissions",
      JSON.stringify(result.data?.permissions)
    );
    localStorage.setItem(
      "NFTdoorAdminRole",
      JSON.stringify(result?.data?.role)
    );

    navigate(
      defaultBrokerAppRoute
        ? hasViewBrokerApp
          ? "/broker-apps"
          : "/applications"
        : "/"
    );

    setLoading(false);
  }, []);

  const handleLoadUser = () => {
    if (admin.token && Number(admin.exp) > new Date().getTime()) {
      API.get({
        url: "/get/get-admin-user/users",
      }).then((result) => {
        setAdmin((prev) => ({ ...prev, ...result.data }));
        if (result?.data) {
          FHConnectUser(
            result.data.id,
            `${result.data.firstName} ${result.data.lastName}`,
            result.data.email,
            result.data.permissions || []
          );
        }
      });
    }
  };

  const handleRefreshUser = () => {
    handleLoadUser();
  };

  useEffect(() => {
    handleLoadUser();
  }, [admin.token, admin.exp]);

  const signInWithToken = useCallback(
    async (admin) => {
      const expiration =
        new Date().getTime() + parseInt(admin.token.expires_in) * 1000;
      setAdmin({
        token: admin.token.id_token,
        exp: expiration,
        permissions: admin.permissions,
      });
      localStorage.setItem(
        "NFTdoorAdminPermissions",
        JSON.stringify(admin.permissions)
      );
      navigate("/loans");
      window.location.reload();
    },
    [admin]
  );

  const tokenExp = localStorage.getItem("NFTdoorAdminTokenExp");
  const currentTime = new Date().getTime();
  const timeUntilExpiration = Number(tokenExp) - currentTime;

  if (timeUntilExpiration >= 0) {
    setTimeout(() => {
      API.get({
        url: "/get/get-admin-user/users",
      }).then((result) => {
        if (result?.data?.permissions) {
          localStorage.setItem(
            "NFTdoorAdminPermissions",
            JSON.stringify(result.data?.permissions)
          );
        }
        if (result?.data?.role) {
          localStorage.setItem(
            "NFTdoorAdminRole",
            JSON.stringify(result?.data?.role)
          );
        }
      });
    }, timeUntilExpiration);
  }

  return (
    <AdminContext.Provider
      value={{
        admin,
        loading,
        setAdmin,
        logout,
        singIn,
        logoutUser,
        signInWithToken,
        handleRefreshUser,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default useAdmin;
