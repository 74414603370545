const themeConfig = {
  app: {
    appNameFirst: "NFTY",
    appNameLast: "Door",
    appLogoImage: require("@src/assets/images/logo/logo.svg").default,
  },
  layout: {
    isRTL: false,
    skin: "bordered",
    type: "vertical",
    contentWidth: "boxed",
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: "floating",
      backgroundColor: "white",
    },
    footer: {
      type: "static",
    },
    customizer: false,
    scrollTop: true,
    toastPosition: "top-right",
  },
};

export default themeConfig;
